@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?to0nss');
    src:  url('../fonts/icomoon.eot?to0nss#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?to0nss') format('truetype'),
      url('../fonts/icomoon.woff?to0nss') format('woff'),
      url('../fonts/icomoon.svg?to0nss#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-website:before {
    content: "\e901";
  }
  .icon-whatsapp:before {
    content: "\e900";
  }
  .icon-phone:before {
    content: "\e92a";
  }
  .icon-trip-advisor:before {
    content: "\e935";
  }
  .icon-youtube:before {
    content: "\e936";
  }
  .icon-instagram:before {
    content: "\e937";
  }
  .icon-facebook:before {
    content: "\e938";
  }
  .icon-whale-watching:before {
    content: "\e930";
  }
  .icon-marine-wildlife:before {
    content: "\e92d";
  }
  .icon-ship:before {
    content: "\e914";
  }
  .icon-sunset:before {
    content: "\e918";
  }
  .icon-snorkel:before {
    content: "\e921";
  }
  .icon-shopping:before {
    content: "\e907";
  }
  .icon-beach-time:before {
    content: "\e90c";
  }
  .icon-drinks:before {
    content: "\e90e";
  }
  .icon-question:before {
    content: "\e908";
  }
  