@font-face {
	font-family: 'HelveticaNeue';
	src: url('../fonts/HelveticaNeue-Medium.eot');
	src: url('../fonts//HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts//HelveticaNeue-Medium.woff') format('woff'),
		url('../fonts//HelveticaNeue-Medium.ttf') format('truetype'),
		url('../fonts//HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
	font-weight: 600;
  font-style: normal;
  font-display: swap;
}