html, body {
    background: #f9f9f9;
    font-size: 14px;
  }
  
  @media screen and (max-width: 850px) {
    html, body {
      font-size: 12px;
    }
  }
  
  
  header {
    margin-bottom: 25px;
  }
  
  .container {
    padding: 50px 20px;
    text-align: center;
  }
  
  .container-max-width {
    width: 100%;
    max-width: 800px;
    display: inline-block;
  }
  
  button, a {
    font-family: "HelveticaNeue";
    border: none;
    background: transparent;
    padding: 15px 40px;
    border-radius: 5px;
    width: 100%;
    margin: 12px 0;
    color: #FFF;
    cursor: pointer;
    font-size: 1.4em;
  }
  
  button span {
    vertical-align: middle;
    margin-right: 15px;
  }